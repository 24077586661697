export const route_base = '/main'

const ROUTES = {
  home: '/',
  data_viz: '/data_viz',
  large_screen: '/large_screen',
  zm_mfc: '/zm_mfc',
  custom_serivces: '/custom_serivces',
  cases: '/cases',
  cases_detail: '/cases_detail',
  blog: '/blog',
  blog_detail: '/blog_detail',
  about_us: '/about_us',
  company_profile: '/company_profile',
  contact_us: '/contact_us',
  join_us: '/join_us',
};

export default ROUTES;

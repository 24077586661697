import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Spinner from './components/Spinner';
import NotFound from './components/NotFound';
import { route_base } from './main/routes';

const MainApp = React.lazy(() => import('./main/App'));
// import MainApp from './main/App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate to={route_base} />} />
        <Route path={`${route_base}/*`} element={
          <Suspense fallback={<Spinner />}>
            <MainApp />
          </Suspense>
        } />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter >
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
